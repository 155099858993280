import React from 'react';
import { useState } from 'react';
import Image from 'next/image';

import styles from './index.module.css';

const slidesData = [
  {
    imageSrc: '/images/new-meeting.webp',
    title: 'Get a link that you can share',
    description: (
      <>
        Click <b>New meeting</b>, then <b>Start a meeting for later</b> to generate a shareable
        link, or choose <b>Start an instant meeting</b> to join a call immediately.
      </>
    ),
  },
  {
    imageSrc: '/images/video-conferencing.webp',
    title: 'See everyone together',
    description:
      'To see more people at once, enable our grid view for a seamless, high‑quality experience.',
  },
  {
    imageSrc: '/images/call-privacy.webp',
    title: 'Your meeting is safe',
    description:
      'No one outside your organisation can join a meeting unless invited or admitted by the host.',
  },
];

export default function WorkingCarousel() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handlePrev = () => {
    setCurrentSlide((prev) => (prev === 0 ? slidesData.length - 1 : prev - 1));
  };

  const handleNext = () => {
    setCurrentSlide((prev) => (prev === slidesData.length - 1 ? 0 : prev + 1));
  };

  const { imageSrc, title, description } = slidesData[currentSlide];

  return (
    <div className={styles.carouselContainer}>
      {/* -- Controls (Previous) -- */}
      <button onClick={handlePrev} className={styles.navButton} aria-label="Previous">
        <svg
          focusable="false"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          className="Hdh4hc cIGbvc NMm5M hhikbc"
        >
          <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z"></path>
        </svg>
      </button>

      {/* -- Slide content -- */}
      <div className={styles.slide}>
        <Image src={imageSrc} alt={title} width={150} height={150} className={styles.image} />
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.description}>{description}</p>
      </div>

      {/* -- Controls (Next) -- */}
      <button onClick={handleNext} className={styles.navButton} aria-label="Next">
        <svg
          focusable="false"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          className="Hdh4hc cIGbvc NMm5M hhikbc"
        >
          <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"></path>
        </svg>
      </button>
    </div>
  );
}
