'use client';

import { useRouter, useSearchParams } from 'next/navigation';
import React, { Suspense, useState } from 'react';
import { encodePassphrase, generateRoomId, randomString } from '@/lib/client-utils';
import styles from '../../styles/Home.module.css';
import WorkingCarousel from '@/components/WorkingCarousel';
import Image from 'next/image';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { CreateMeetingForLaterDialog } from '@/components/CreateMeetingForLaterDialog';
import Header from '@/components/Header';
import { ProjectDetails } from '@/lib/types';

const StartMeetingOptionsButton = ({
  onStartInstantMeeting,
  onCreateMeetingForLater,
}: {
  onStartInstantMeeting: VoidFunction;
  onCreateMeetingForLater: VoidFunction;
}) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger className={`lk-button button`}>
        <Image src={'/images/icons/video-call.svg'} width={24} height={24} alt={'Video Call'} /> New
        Meeting
      </DropdownMenuTrigger>
      <DropdownMenuContent className={styles.meetingOptionsContent}>
        <DropdownMenuItem className={styles.meetingOption} onClick={onCreateMeetingForLater}>
          <Image src={'/images/icons/link.svg'} alt={'Link'} width={24} height={24} />
          Start a meeting for later
        </DropdownMenuItem>
        <DropdownMenuItem className={styles.meetingOption} onClick={onStartInstantMeeting}>
          <Image src={'/images/icons/add.svg'} alt={'Add'} width={24} height={24} />
          Start an instant meeting
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

function Tabs(props: React.PropsWithChildren<{}>) {
  const searchParams = useSearchParams();
  const tabIndex = searchParams?.get('tab') === 'custom' ? 1 : 0;

  const router = useRouter();
  function onTabSelected(index: number) {
    const tab = index === 1 ? 'custom' : 'demo';
    router.push(`/?tab=${tab}`);
  }

  let tabs = React.Children.map(props.children, (child, index) => {
    return (
      <button
        className="lk-button"
        onClick={() => {
          if (onTabSelected) {
            onTabSelected(index);
          }
        }}
        aria-pressed={tabIndex === index}
      >
        {/* @ts-ignore */}
        {child?.props.label}
      </button>
    );
  });

  return (
    <div className={styles.tabContainer}>
      <div className={styles.tabSelect}>{tabs}</div>
      {/* @ts-ignore */}
      {props.children[tabIndex]}
    </div>
  );
}

function DemoMeetingTab(props: { label: string }) {
  const router = useRouter();
  const [e2ee, setE2ee] = useState(false);
  const [sharedPassphrase, setSharedPassphrase] = useState(randomString(64));
  const [meetLink, setMeetLink] = useState('');
  const [createMeetingDialogOpen, setCreateMeetingDialogOpen] = useState(false);
  const [scheduledMeetingLink, setScheduledMeetingLink] = useState<string>('');

  const startMeeting = () => {
    if (e2ee) {
      router.push(`/rooms/${generateRoomId()}#${encodePassphrase(sharedPassphrase)}`);
    } else {
      router.push(`/rooms/${generateRoomId()}?ht=1`);
    }
  };

  const handleJoinMeet: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    router.push(`/rooms/${meetLink}`);
  };

  const createMeetingForLater = () => {
    const roomPath = `/rooms/${generateRoomId()}`;
    const fullPath = e2ee ? `${roomPath}#${encodePassphrase(sharedPassphrase)}` : roomPath;

    // Convert to an absolute URL
    const absoluteUrl = `${window.location.origin}${fullPath}`;

    setScheduledMeetingLink(absoluteUrl);
    setCreateMeetingDialogOpen(true);
  };

  return (
    <div className={styles.tabContent}>
      <CreateMeetingForLaterDialog
        variant={'dialog'}
        open={createMeetingDialogOpen}
        onOpenChange={() => setCreateMeetingDialogOpen(false)}
        meetLink={scheduledMeetingLink}
      />
      <div className={styles.tabOptions}>
        <StartMeetingOptionsButton
          onStartInstantMeeting={startMeeting}
          onCreateMeetingForLater={createMeetingForLater}
        />
        <form onSubmit={handleJoinMeet} className={styles.meetLinkHolder}>
          <div className={styles.meetLinkInputHolder}>
            <Image src={'/images/icons/keyboard.svg'} alt={'Keyboard'} width={24} height={24} />
            <input
              id="meetLink"
              type="text"
              required
              placeholder="Enter a meeting code"
              value={meetLink}
              onChange={(e) => setMeetLink(e.target.value)}
              pattern="[A-Za-z0-9]{4}-[A-Za-z0-9]{4}"
              onInvalid={(e) => {
                (e.target as HTMLInputElement).setCustomValidity('Meeting code Invalid!');
              }}
              onInput={(e) => {
                (e.target as HTMLInputElement).setCustomValidity('');
              }}
              className={`${styles.meetingLinkInput}`}
              autoComplete={'off'}
            />
          </div>
          <button
            type="submit"
            className={`lk-button ${styles.joinBtn}`}
            disabled={!meetLink?.length}
          >
            Join
          </button>
        </form>
      </div>
      {/*<div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>*/}
      {/*  <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>*/}
      {/*    <input*/}
      {/*      id="use-e2ee"*/}
      {/*      type="checkbox"*/}
      {/*      checked={e2ee}*/}
      {/*      onChange={(ev) => setE2ee(ev.target.checked)}*/}
      {/*    ></input>*/}
      {/*    <label htmlFor="use-e2ee">Enable end-to-end encryption</label>*/}
      {/*  </div>*/}
      {/*  {e2ee && (*/}
      {/*    <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>*/}
      {/*      <label htmlFor="passphrase">Passphrase</label>*/}
      {/*      <input*/}
      {/*        id="passphrase"*/}
      {/*        type="password"*/}
      {/*        value={sharedPassphrase}*/}
      {/*        onChange={(ev) => setSharedPassphrase(ev.target.value)}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*</div>*/}
    </div>
  );
}

function CustomConnectionTab(props: { label: string }) {
  const router = useRouter();

  const [e2ee, setE2ee] = useState(false);
  const [sharedPassphrase, setSharedPassphrase] = useState(randomString(64));

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    const serverUrl = formData.get('serverUrl');
    const token = formData.get('token');
    if (e2ee) {
      router.push(
        `/custom/?liveKitUrl=${serverUrl}&token=${token}#${encodePassphrase(sharedPassphrase)}`,
      );
    } else {
      router.push(`/custom/?liveKitUrl=${serverUrl}&token=${token}`);
    }
  };
  return (
    <form className={styles.tabContent} onSubmit={onSubmit}>
      <p style={{ marginTop: 0 }}>
        Connect LiveKit Meet with a custom server using LiveKit Cloud or LiveKit Server.
      </p>
      <input
        id="serverUrl"
        name="serverUrl"
        type="url"
        placeholder="LiveKit Server URL: wss://*.livekit.cloud"
        required
      />
      <textarea
        id="token"
        name="token"
        placeholder="Token"
        required
        rows={5}
        style={{ padding: '1px 2px', fontSize: 'inherit', lineHeight: 'inherit' }}
      />
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
          <input
            id="use-e2ee"
            type="checkbox"
            checked={e2ee}
            onChange={(ev) => setE2ee(ev.target.checked)}
          ></input>
          <label htmlFor="use-e2ee">Enable end-to-end encryption</label>
        </div>
        {e2ee && (
          <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
            <label htmlFor="passphrase">Passphrase</label>
            <input
              id="passphrase"
              type="password"
              value={sharedPassphrase}
              onChange={(ev) => setSharedPassphrase(ev.target.value)}
            />
          </div>
        )}
      </div>

      <hr
        style={{ width: '100%', borderColor: 'rgba(255, 255, 255, 0.15)', marginBlock: '1rem' }}
      />
      <button
        style={{ paddingInline: '1.25rem', width: '100%' }}
        className="lk-button"
        type="submit"
      >
        Connect
      </button>
    </form>
  );
}

export default function HomePage({ data }: { data: ProjectDetails }) {
  return (
    <>
      <Header logo={data?.logo} />
      <main className={styles.main} data-lk-theme="default">
        <div className={`title-holder`}>
          <h1>{data?.name || 'Welcome to Your Self-Hosted Video Conferencing Hub'}</h1>
          <p>
            {data?.description ||
              'Connect, collaborate and celebrate from anywhere without sacrificing security or ownership.'}
          </p>
          <Suspense fallback="Loading">
            {/*<Tabs>*/}
            <DemoMeetingTab label="Demo" />
            {/*<CustomConnectionTab label="Custom" />*/}
            {/*</Tabs>*/}
          </Suspense>
          <WorkingCarousel />
        </div>

        <footer className={'footer-link'}>
          <a href={'https://reconv.in'} rel="noopener">
            Learn More
          </a>{' '}
          about Custom Meet
        </footer>
      </main>
    </>
  );
}
