'use client';

import * as React from 'react';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import styles from '../../styles/Home.module.css';
import Image from 'next/image';
import { toast } from '@/hooks/use-toast';
import { Card, CardContent } from '@/components/ui/card';

interface JoinInfoDialogProps {
  variant?: 'dialog' | 'card';
  open: boolean;
  onOpenChange: VoidFunction;
  meetLink: string;
}

function MeetingInfoContent({
  variant,
  title,
  description,
  meetLink,
  onCopy,
}: {
  variant?: 'dialog' | 'card';
  title: string;
  description: string;
  meetLink: string;
  onCopy: () => void;
}) {
  return (
    <div className={'flex flex-col gap-2 justify-between'}>
      <h2 className={styles.dialogTitle}>{title}</h2>
      <p className={styles.dialogSubtitle}>{description}</p>

      <div className={styles.linkHolder}>
        <p className={styles.link}>{meetLink}</p>
        <button onClick={onCopy}>
          <Image
            className="cursor-pointer"
            src={'/images/icons/copy.svg'}
            width={24}
            height={24}
            alt="Copy Icon"
          />
        </button>
      </div>
    </div>
  );
}

export function CreateMeetingForLaterDialog({
  variant,
  open,
  onOpenChange,
  meetLink,
}: JoinInfoDialogProps) {
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(meetLink);
      toast({
        title: 'Link copied!',
        description: 'Your meeting link has been copied to the clipboard.',
        duration: 3000,
      });
    } catch (err) {
      toast({
        variant: 'destructive',
        title: 'Failed to copy',
        description: 'Could not copy the link to your clipboard.',
        duration: 3000,
      });
    }
  };

  const title = "Here's your joining information";
  const description =
    'Send this to people that you want to meet with. Make sure you save it so you can use it later, too.';

  if (variant === 'dialog') {
    return (
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className={`w-[400px] min-h-[260px]  gap-2 ${styles.dialog}`}>
          <MeetingInfoContent
            title={title}
            description={description}
            meetLink={meetLink}
            onCopy={handleCopy}
          />
        </DialogContent>
      </Dialog>
    );
  }

  // "card" variant
  return (
    <Card
      className={`fixed right-4 top-4 md:left-6 md:bottom-6 md:top-auto w-[400px] p-4 ${styles.card}`}
    >
      <CardContent className={`relative`}>
        <span
          className={`cursor-pointer absolute right-2 top-2 hover:bg-gray-200`}
          onClick={onOpenChange}
        >
          <Image src={'/images/icons/close.svg'} width={24} height={24} alt={'Close'} />
        </span>
        <MeetingInfoContent
          title={'Your Meeting is ready'}
          description={description}
          meetLink={meetLink}
          onCopy={handleCopy}
        />
      </CardContent>
    </Card>
  );
}
